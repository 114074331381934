/* .dashboard-card {
  background: #fff;
  border-radius: 4px;
  min-height: 250px;
  margin: 1rem;
  padding: 4px 8px;
  position: relative;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.dashboard-card:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
} */

.dashboard .card-title {
  display: flex;
  align-items: center;
}

.dashboard-card div:not(:last-child) {
  margin-bottom: 8px;
}